.tabs {
  &__menu {
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(163, 172, 179, 0.3);
  }

  &__btn {
    position: relative;
    margin-right: 32px;
    font-weight: 600;
    color: var(--secondary);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;

    padding-bottom: 19px;
    &:after {
      content: '';
      width: 0;
      transition: 0.3s;
    }


    &--active,
    &:hover {
      color: var(--red);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 2px;
        background-color: var(--red);
      }
    }
  }

  &__item {
  }
  &__panel{
    position: relative;
  }
}
