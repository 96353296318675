@import 'src/assets/styles/mixins';

.select-component {
  display: inline-flex;
  min-width: 200px;

  .select {
    &__control {
      width: 100%;
      height: 50px;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary);
      background-color: var(--white);
      border: 1px solid rgba(32, 69, 105, 0.3);
      border-radius: 4px;
      cursor: pointer;

      &--is-focused,
      &:hover {
        border-color: var(--info);
        box-shadow: none;
      }

      &--is-disabled {
        background-color: var(--light);
        border-color: var(--light);
      }

      &--menu-is-open {
      }
    }

    &__value-container {
      padding: 2px 15px;
      cursor: pointer;
    }

    &__placeholder {
      margin-left: 0;
      margin-right: 0;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary);
      opacity: 0.5;
    }

    &__single-value {
      margin-left: 0;
      margin-right: 0;
    }

    &__indicator {
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    // Menu
    &__menu {
      z-index: 2;
      margin-top: 5px;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.03), 0px 4px 6px rgba(154, 166, 178, 0.3);
    }

    &__menu-list {
      padding-top: 5px;
      padding-bottom: 5px;
      @include scrollbar(5px, var(--primary), var(--light), 5px, 2px);
    }

    &__option {
      position: relative;
      padding: 6px 15px;
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;

      &--is-focused {
        font-weight: 800;
        color: var(--primary);
        background-color: var(--light);
      }

      &--is-selected {
        font-weight: 800;
        color: var(--primary);
        background-color: var(--white);

        &:after {
          content: none;
        }
      }
    }
  }

  .css-b8ldur-Input {
    margin: 0;
  }

  .select-indicator {
    width: 24px;
    height: 24px;
    transition: 0.3s;
  }

  .indicator-active {
    transform: scale(-1);
  }
}

// Searchable select
.select-component--searchable {
  .select {
    &__control,
    &__value-container {
      cursor: text;
    }
  }
}

// With search icon
.select-component--search-icon {
  .select {
    &__value-container {
      padding-left: 55px;

      &:before {
        content: url('./icons/search.svg');
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
      }
    }

    &__dropdown-indicator {
      display: none;
    }
  }
}
