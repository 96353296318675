.activity {
  background: var(--white);
  border-radius: 16px;
  margin: 24px 0;
  padding: 24px 32px;
  box-sizing: border-box;
  width: calc(50% - 12px);
  position: relative;
  h3{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: var(--primary);
  }
  .chart_wrapper{
    padding-bottom: 24px;
    width: 100%;
    //&::-webkit-scrollbar {
    //  width: 4px;
    //  height: 4px;
    //}
    //&::-webkit-scrollbar-track {
    //  -webkit-border-radius: 2px;
    //  background: rgba(163, 172, 179, 0.2);
    //}
    //&::-webkit-scrollbar-thumb {
    //  background: var(--secondary);
    //  border-radius: 10px;
    //}
    //&::-webkit-scrollbar-thumb:window-inactive {
    //  background: var(--secondary);
    //  border-radius: 10px;
    //}
    //overflow: auto;
    //svg{
    //  width: 100%;
    //  min-width: 700px;
    //}
  }
  .chart_info{
    display: flex;
    align-items: center;
    padding-top: 35px;
    padding-left: 37px;
    >div{
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      color: var(--primary);
      margin-right: 32px;
      span{
        margin-right: 8px;
        width: 12px;
        height: 12px;
        border-radius: 2px;
      }
    }
  }
}
