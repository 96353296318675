.accordion-mui {
  .accordion-mui-item {
    color: var(--white);
    background-color: var(--info);

    &--color-default {
      color: var(--white);
      background-color: var(--info);
    }

    &--color-primary {
      color: var(--white);
      background-color: var(--primary);
    }

    &--color-secondary {
      color: var(--white);
      background-color: var(--error);
    }

    &--min-spaces {
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &__expand-icon {
      color: inherit;
    }
  }
}
