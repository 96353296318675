@import 'src/assets/styles/mixins';

.examples {
  display: flex;

  &__nav {
    overflow-y: auto;
    padding: 20px 0;
    width: 20%;
    background-color: var(--white);
    height: calc(100vh - 156px);
    @include scrollbar(8px, #b8c8d6, var(--white), 5px, 2px);
  }

  &__nav-link {
    display: block;
    padding: 10px 20px;
    font-weight: 500;

    &.active,
    &:hover {
      color: var(--white);
      background-color: var(--info);
    }
  }

  &__content {
    overflow-y: auto;
    width: 80%;
    height: calc(100vh - 156px);
    @include scrollbar(15px, var(--primary), var(--light), 5px, 2px);
  }

  .demo-article {
    border: 1px solid var(--gray);
    border-radius: 4px;

    &__title {
      text-transform: capitalize;
    }

    &__text {
      text-transform: capitalize;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
