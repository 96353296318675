.auth-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  &:before{
    position: absolute;
    content: "";
    background: var(--green);
    opacity: 0.1;
    filter: blur(500px);
    width: 1000px;
    height: 1000px;
    right: -280px;
    top: -400px;
  }
  &:after{
    position: absolute;
    content: "";
    width: 1000px;
    height: 1000px;
    left: -280px;
    top: 400px;
    background: var(--red);
    opacity: 0.1;
    filter: blur(500px);
  }
  &__content {
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 112px 96px 96px;
    max-width: 640px;
    width: 100%;
    box-sizing: border-box;
    background: var(--white);
    border-radius: 16px;
    position: relative;

  }
  .logo{
    position: absolute;
    left: calc(50% - 48px);
    top: -48px;
  }
}

.auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
  }

  &__desc {
    margin-bottom: 48px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.25px;
    color: var(--secondary);
  }

  &__input {
    margin-bottom: 24px;
  }

  &__input_box{
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    >span{
      display: flex;
      margin-bottom: 4px;
      width: 50%;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      color: var(--secondary);
    }
    >p{
      margin-bottom: 4px;
      width: 50%;
      display: flex;
      justify-content: flex-end;
      a{
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.25px;
        color: var(--red);
      }
    }
  }

  &__btn {
    display: flex;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .backlink{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.25px;
    color: var(--red);
    margin: 96px 0 -48px 0;
  }

  &__rounded-wrap {
    width: 100%;
    height: 166px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background_second);
    border-radius: 8px;
  }
}
