@import 'reset';
@import 'vars';
@import 'mixins';
@import '../fonts/Inter/stylesheet';
@import '../fonts/Montserrat/stylesheet';
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap&subset=cyrillic-ext');

body {
  font-family: var(--font);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.25px;
  color: var(--primary);
  background-color: var(--background);
  @include scrollbar(10px, var(--primary), var(--light), 5px, 2px);
}

a {
  color: var(--info);
}

.root {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}

.page-wrap {
  flex: 1;
  padding-left: 48px;
  padding-right: 48px;
  width: calc(100% - 240px);
  margin: 0 0 0 auto;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: var(--container);
  width: 100%;
}

.card-wrap {
  padding: 24px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}
.page_wrapper {
  position: relative;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 41px;
}

h2,
.h2,
h3,
.h3 {
  font-weight: 600;
  text-transform: uppercase;
}

h3,
.h3 {
  font-size: 12px;
  line-height: 18px;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--info);

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }
}

.sort_btn {
  display: flex;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    margin-left: 6px;
    svg {
      margin: 2px 0;
    }
  }
}
.up {
  .up_icon {
    path {
      opacity: 1;
      stroke: var(--secondary);
      fill: var(--secondary);
      path {
        stroke: var(--secondary);
      }
    }
  }
}
.down {
  .up_down {
    path {
      opacity: 1;
      stroke: var(--secondary);
      fill: var(--secondary);
      path {
        stroke: var(--secondary);
      }
    }
  }
}

.search-field {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    pointer-events: none;
  }

  input {
    padding: 12px 12px 12px 40px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--primary);
    background-color: var(--background_second);
    border-radius: 8px;

    &::placeholder {
      color: var(--secondary);
    }
  }
}

// Table
.table {
  tr {
    border-bottom: 1px solid rgba(163, 172, 179, 0.3);
  }
  th {
    vertical-align: middle;
    padding: 16px 0;
    color: var(--secondary);
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    button {
      text-align: left;
      color: var(--secondary);
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
    }
  }

  td {
    vertical-align: middle;
    //padding: 20px 0;
    height: 63px;
  }
}

.table-inner {
  display: flex;
  gap: 16px;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.gap-25 {
  gap: 25px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.good-hover {
  transition: opacity ease 0.3s;

  &:hover {
    opacity: 0.7;
  }
}

.card-hover {
  cursor: pointer;
  transition: margin-top ease 0.3s, margin-bottom ease 0.3s;

  &:hover {
    margin-top: -3px;
    margin-bottom: 3px;
  }
}

.no-select {
  user-select: none;
}

// Animations
.pulse {
  &:hover {
    animation: pulse 2s infinite;
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 1;
  }
}

// React Toastify
.Toastify__toast {
  font-family: inherit;

  &--error {
    background-color: var(--error);
  }

  &--success {
    background-color: var(--success);
  }
}

.highcharts-credits {
  display: none;
}
.load {
  position: absolute;
  left: 50%;
  top: 50%;
}
.no_load {
  display: flex;
  width: 100%;
  flex-direction: column;
}

ul[role='listbox'] {
  z-index: 9999;

  > li {
    padding: 6px;
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
}
