.input-mui {
  &__label {
    font-family: inherit;
    font-size: 14px;
    transform: translate(14px, 17px) scale(1);

    & + .MuiInput-formControl {
      margin-top: 0;
    }

    &.input-mui__label--focused {
      color: var(--info);
    }

    &.input-mui__label--shrink {
      transform: translate(0, -6px) scale(0.75);
    }

    &.input-mui__label--error {
      color: var(--error);
    }
  }

  .MuiInputLabel-outlined.input-mui__label--shrink {
    transform: translate(13px, -6px) scale(0.75);
  }

  .MuiInputLabel-filled.input-mui__label--shrink {
    transform: translate(13px, 2px) scale(0.75);
  }

  &__root {
    padding-right: 0;
    max-width: 200px;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    font-weight: 400;
    letter-spacing: normal;
    color: var(--dark);
    border-radius: 4px;

    &--disabled {
      color: var(--gray);
    }

    &--multiline {
      padding: 0;
    }

    &.MuiInputBase-fullWidth {
      max-width: none;
    }
  }

  &__input {
    box-sizing: border-box;
    padding: 14px 40px 14px 14px;
    height: 48px;

    &.MuiOutlinedInput-inputMarginDense {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 38px;
    }
  }

  // Outline input
  .MuiOutlinedInput-root {
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--info);
      }
    }

    &.input-mui__root--focused {
      .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: var(--info);
      }
    }

    &.input-mui__root--disabled {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--gray);
      }
    }

    &.input-mui__root-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--error);
      }
    }
  }

  .MuiOutlinedInput-input {
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--gray);
    transition: border-color 0.3s;
  }

  // Standard input & Filled input
  .MuiInput-underline,
  .MuiFilledInput-underline {
    &:hover:not(.input-mui__root--disabled):before {
      border-bottom: 1px solid var(--info);
    }

    &:before {
      border-color: var(--gray);
    }

    &:after {
      border-bottom: 1px solid var(--info);
    }
  }

  // Filled input
  .MuiFilledInput-root {
    background-color: var(--light);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__end-adornment {
    position: absolute;
    right: 0;
  }

  &__helper-text {
    position: absolute;
    bottom: -20px;
    font-family: inherit;

    &.Mui-error {
      color: var(--error);
    }
  }

  &__error-icon {
    margin-right: 14px;
    cursor: pointer;
  }
}
