// Circular loader
.circular-loader-mui {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 10;

  &--bg {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__root {
    margin: auto;

    &--color-primary {
      color: var(--primary);
    }

    &--color-secondary {
      color: var(--error);
    }
  }
}

// Linear loader
.linear-loader-mui {
  height: 2px;

  &--bg-primary {
    background-color: rgba(55, 150, 246, 0.3);
  }

  &--bg-secondary {
    background-color: rgba(228, 75, 75, 0.3);
  }

  &__bar-primary {
    background-color: var(--info);
  }

  &__bar-secondary {
    background-color: var(--error);
  }
}
