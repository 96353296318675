.multi-select-mui {
  position: relative;
  display: inline-flex;
  min-width: 280px;
  height: 48px;

  &--with-label {
    margin-top: 22px;
  }

  &__label {
    position: absolute;
    top: -22px;
    font-size: 12px;

    &--disabled {
      color: var(--gray);
    }
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    font-size: 14px;
    color: var(--primary);
    opacity: 0.5;
  }

  &__select-wrap {
    width: 100%;
    font-family: inherit;

    &:before,
    &:after {
      display: none;
    }
  }

  &__select {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-right: 32px;
    height: 100%;
    font-size: 14px;
    color: var(--primary);
    border: 1px solid rgba(32, 69, 105, 0.3);
    border-radius: 4px;
    transition: border-color 0.3s;

    &:hover:not(.multi-select-mui__select--disabled),
    &:focus {
      background: transparent;
      border-color: var(--info);
      border-radius: 4px;
    }

    &--disabled {
      color: var(--gray);
      border-color: var(--gray);
    }
  }

  &__icon {
    box-sizing: content-box;
    padding-left: 8px;
    padding-right: 8px;
  }

  &__checkbox {
    margin-right: 8px;
  }

  &__list {
    margin-left: 1px;
    margin-top: 4px;
  }

  &__list-item {
    font-family: var(--font);
    font-size: 14px;
    color: var(--primary);
  }
}
