.user_profiles {
  padding: 24px 32px 32px;
  background: var(--white);
  margin-bottom: 64px;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  h3{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 24px;
  }
  .filter_search{
    position: relative;
    margin-bottom: 16px;
    input{
      width: 360px;
      height: 48px;
      background: var(--background_second);
      border-radius: 8px;
      padding: 0 16px 0 40px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.25px;
      color: var(--primary);
      &::placeholder{
        color: var(--secondary);
      }
    }
    >span{
      position: absolute;
      left: 16px;
      top: calc(50% - 8px);
      cursor: pointer;
    }
  }
  .table_users{

    tbody{
      tr{
        td{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: var(--primary);
        }
        td:nth-child(1){
          font-weight: 600;
          a{
            display: flex;
            align-items: center;
            color: var(--primary);
            >svg{
              width: 12px;
              height: 12px;
              margin-top: 4px;
              margin-left: 4px;
            }
            span{
              display: flex;
              align-items: center;
              img{
                background-size: 100% 100%;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 8px;

              }
              svg{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 8px;
              }
            }
          }
        }
        td:nth-child(4) {
          display: flex;
          align-items: center;
          >span{
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.25px;
            color: var(--red);
           >span{
             margin-right: 4px;
             padding: 3px 8px;
             background: var(--red_light);
             border-radius: 20px;
             font-weight: 600;
             font-size: 12px;
             line-height: 18px;
             letter-spacing: -0.25px;
             color: var(--red);
           }
          }
        }

      }
    }
    thead, tbody{
      >tr {

        & > :nth-child(1) {
          width: 22%;

        }
        & > :nth-child(2) {
          width: 18%;
        }
        & > :nth-child(3) {
          width: 15%;
        }
        & > :nth-child(4) {
          width: 25%;

        }
        & > :nth-child(5) {
          width: 10%;
        }
        & > :nth-child(6) {
          width: 10%;
        }
      }

    }

  }
}
.empty_message{
  display: flex;
  color: var(--secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
  margin-top: 30px;
  >span{
    margin-left: 3px;
  }
}