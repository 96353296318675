.dialog-mui {
  &__paper {
    min-width: 720px;
    color: inherit;
    border-radius: 5px;
    box-shadow: none;
  }

  &__content {
    position: relative;
    overflow: auto;
    padding: 25px 30px;
    max-height: 850px;
    background-color: var(--white);

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--light);
      border-radius: 2px;
      -webkit-border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 5px;
      -webkit-border-radius: 5px;

      &:window-inactive {
        background-color: var(--secondary);
      }
    }
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    width: 32px;
    height: 32px;

    path {
      transition: 0.3s ease;
    }

    &:hover {
      path {
        opacity: 1;
        fill: var(--error);
      }
    }
  }

  &__close-icon {
    margin: auto;
  }
}
