.tooltip-mui {
  font-family: inherit;

  &--error-color {
    background-color: var(--error);

    .tooltip-mui__arrow {
      color: var(--error);
    }
  }

  &__arrow {
  }
}
