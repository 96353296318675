.user_details{
}

.back_info{
  margin: 24px 0;
  display: flex;
  align-items: center;
  a{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: var(--red);
  }
  svg{
    margin: 0 8px;
  }
  span{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: var(--primary);
  }
}

.user{
  position: relative;
  background: var(--white);
  margin-bottom: 24px;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  align-items: center;
  .avatar{
    margin-right: 32px;
    img{
      min-width: 200px;
      height: 200px;
      border-radius: 8px;
      background-size: cover;
    }
    svg{
      width: 200px;
      height: 200px;
      border-radius: 8px;
    }
  }
  .name{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.25px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    svg{
      margin-left: 6px;
    }
  }
  .info{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: var(--secondary);
    svg{
      margin-right: 4px;
      width: 12px;
      height: 12px;
    }
    >span{
      margin-right: 2px;
    }
    >div{
      margin-right: 2px;
    }
  }
  .interests{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 0 8px;
    >div{
      margin-bottom: 8px;
      margin-right: 4px;
      padding: 3px 8px;
      background: var(--red_light);
      border-radius: 20px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.25px;
      color: var(--red);
    }
  }
  .desc{
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.25px;
  }
}

.subscriptions {
  margin-bottom: 64px;
  padding: 24px 32px 32px;
  background: var(--white);
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h3{
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 44px;
  }

  .table_paid{
    width: 100%;
    margin-top: -16px;

    tbody{
      tr{
        td{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: var(--primary);
        }
      }
    }

  }
  .qr_code{
    width: 100%;
    margin-top: -16px;
    tbody{
      tr{
        td{
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.25px;
          color: var(--primary);
        }
      }
    }
  }
}
