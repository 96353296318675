@import 'src/assets/styles/mixins';

.dashboard {
  padding-top: 24px;
  padding-bottom: 24px;

  &Inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  @include mq(lg-down) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
