.root {
  padding: 24px 32px;
  width: calc(50% - 12px);
  min-height: 382px;
  background-color: var(--white);
  border-radius: 16px;
  margin: 24px 24px 24px 0;
  position: relative;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 48px;
  margin-top: 48px;
}

.chart_wrap {
  position: relative;
}

.chart {
  width: 200px;
  height: 200px;
}

.chart_inner {
  position: absolute;
  top: 72px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: center;
}

.chart_sum {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}

.chart_label {
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--secondary);
}

.list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.item {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  span {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }

  b {
    margin-left: 8px;
    margin-right: 4px;
  }
}
