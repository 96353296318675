.button-mui {
  padding: 6px 16px;

  min-width: 160px;
  height: 48px;
  font-family: var(--font);
  text-transform: capitalize;
  border-radius: 4px;
  box-shadow: none;
  letter-spacing: -0.25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  &:hover {
    opacity: 1;
  }

  &:disabled {
  }

  // variant='contained'
  &--contained {
    color: var(--white);
    background-color: var(--red);

    &:hover {
      background-color: darken(#EC5B75, 10%);
    }

    &-primary {
      background-color: var(--primary);

      &:hover {
        background-color: darken(#EC5B75, 10%);
      }
    }

    &-secondary {
      background-color: var(--error);

      &:hover {
        background-color: darken(#e44b4b, 10%);
      }
    }
  }

  // variant='outlined'
  &--outlined {
    color: var(--info);
    border-color: var(--info);

    &:hover {
      background-color: lighten(#3796f6, 35%);
    }

    &-primary {
      color: var(--primary);
      border-color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);
      border-color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // variant='text'
  &--text {
    color: var(--info);

    &:hover {
      background-color: lighten(#3796f6, 35%);
    }

    &-primary {
      color: var(--primary);

      &:hover {
        background-color: lighten(#204569, 60%);
      }
    }

    &-secondary {
      color: var(--error);

      &:hover {
        background-color: lighten(#e44b4b, 35%);
      }
    }
  }

  // size='small'
  &--small {
    min-width: 105px;
    height: 35px;
    font-size: 12px;
    line-height: 16px;
  }

  // size='large'
  &--large {
    min-width: 168px;
    height: 56px;
    font-size: 18px;
    line-height: 20px;
  }

  // startIcon & endIcon max size
  &__start-icon svg,
  &__end-icon svg {
    max-width: 20px;
    max-height: 20px;
  }
}
