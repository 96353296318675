@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
    url('Montserrat-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
    url('Montserrat-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
    url('Montserrat-BoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('Montserrat-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
    url('Montserrat-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url('Montserrat-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
    url('Montserrat-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
    url('Montserrat-ExtraBoldItalic.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Light'), local('Montserrat-Light'), url('Montserrat-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Black'), local('Montserrat-Black'), url('Montserrat-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
    url('Montserrat-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
    url('Montserrat-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Thin'), local('Montserrat-Thin'), url('Montserrat-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
    url('Montserrat-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url('Montserrat-ExtraBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
