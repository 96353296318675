.side-bg {
  position: absolute;
  left: 0;
  top: 0;
  min-height: calc(100vh - 97px);
  height: 100%;
  width: 240px;
  background: var(--white);
  padding-top: 64px;
  display: flex;
  flex-direction: column;

  a{
    position: relative;
    padding: 0 48px;
    height: 64px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.25px;
    color: #A3ACB3;
    transition: all ease 0.5s;
    &:hover{
      color: var(--red);
      svg{
        path {
          fill: var(--red);
        }
      }
    }
    &:before{
      transition: all ease 0.5s;
      position: absolute;
      content: "";
      left: -4px;
      top: 0;
      width: 4px;
      height: 64px;
      background: var(--white);
    }
    svg{
      margin-right: 16px;
      path {
        transition: all ease 0.5s;
      }
    }
  }
  .active{
    background: #FDEFF1;
    color: var(--red);
    font-weight: 600;
    &:before{
      left: 0px;
      background: var(--red);
    }
    svg{
      path {
        fill: var(--red);
      }
    }

  }

}
