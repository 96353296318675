.root {
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}

.table {
  width: 100%;

  th:nth-child(3) button {
    width: min-content;
  }

  td {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

    span {
      color: var(--secondary);
    }
  }
}

.empty_message {
  display: flex;
  color: var(--secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
  margin-top: 30px;
  max-width: 700px;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  > span {
    margin-left: 3px;
    //eclipse text
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
  }
}
