.list {
  padding: 24px 32px 32px;
  margin-bottom: 64px;
  background: var(--white);
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.25px;
    text-transform: uppercase;
    color: var(--primary);
    margin-bottom: 44px;
  }
  .list_block {
    .filter_search {
      position: relative;
      margin-bottom: 16px;
      input {
        width: 360px;
        height: 48px;
        background: var(--background_second);
        border-radius: 8px;
        padding: 0 16px 0 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.25px;
        color: var(--primary);
        &::placeholder {
          color: var(--secondary);
        }
      }
      > span {
        position: absolute;
        left: 16px;
        top: calc(50% - 8px);
        cursor: pointer;
      }
    }
    .table_list {
      width: 100%;

      tbody {
        tr {
          td {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.25px;
            color: var(--primary);
          }
          td:first-child {
            //eclipse text
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 190px;
            padding-right: 20px;
          }
          td:nth-child(3) {
            display: flex;
            width: 315px;
            align-items: center;
            flex-wrap: wrap;
            > div {
              margin-right: 4px;
              padding: 3px 8px;
              background: var(--red_light);
              border-radius: 20px;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: -0.25px;
              white-space: nowrap;
              color: var(--red);
            }
          }
          td:nth-child(5) {
            span {
              color: var(--secondary);
            }
          }
        }
      }
    }
  }
}

.empty_message {
  display: flex;
  color: var(--secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
  margin-top: 30px;
  max-width: 700px;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  > span {
    margin-left: 3px;
    //eclipse text
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px;
  }
}
