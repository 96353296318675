.root {
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  width: calc(50% - 12px);
  min-height: 382px;
  background-color: var(--white);
  border-radius: 16px;
  margin: 24px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.link {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  color: var(--red);
}

.map_wrapper {
  position: relative;
  flex: 1;
  width: 100%;

  .search {
    position: absolute;
    z-index: 1000;
    top: 8px;
    left: 8px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  }
}

.event {
  position: absolute;
  z-index: 1000;
  bottom: 8px;
  left: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background-color: var(--white);
  border-radius: 8px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));

  @media (hover) {
    &:hover,
    &:focus-visible {
      b {
        color: var(--red);
      }
    }
  }

  img {
    border-radius: 8px;
    aspect-ratio: 1;
  }

  b {
    display: block;
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary);
    transition: color 0.3s;
  }

  span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--secondary);
    > svg {
      margin-left: 0;
    }
  }

  svg {
    margin-left: auto;
  }
}

.close_btn {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  padding: 3px;
  justify-content: center;

  svg {
    fill: black;
  }

  :hover {
    > * {
      fill: var(--red);
      transition: all 0.3s;
    }
  }
}
