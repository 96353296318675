.header {
  z-index: 1;
  background-color: var(--white);
  border-bottom: 1px solid rgba(163, 172, 179, 0.3);
  &__container {
    padding: 0 48px;
    display: flex;
    min-height: 96px;
    align-items: center;
    justify-content: space-between;
  }
  &__logout {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    letter-spacing: -0.25px;
    color: #333333;
    svg{
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
