.root {
  padding-top: 24px;
  padding-bottom: 64px;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 24px 32px 32px;
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
}

.quantity {
  color: var(--secondary);
}

.table {
  width: 100%;

  td {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;

    span {
      color: var(--secondary);
    }
  }

  .interest {
    padding: 6px 16px;
    font-weight: 600;
    color: var(--red);
    background-color: var(--red_light);
    border-radius: 48px;
  }
}
.filter_search{
  position: relative;
  margin-bottom: 16px;
  input{
    width: 360px;
    height: 48px;
    background: var(--background_second);
    border-radius: 8px;
    padding: 0 16px 0 40px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.25px;
    color: var(--primary);
    &::placeholder{
      color: var(--secondary);
    }
  }
  >span{
    position: absolute;
    left: 16px;
    top: calc(50% - 8px);
    cursor: pointer;
  }
}
.empty_message{
  display: flex;
  color: var(--secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.25px;
  margin-top: 30px;
  >span{
    margin-left: 3px;
  }
}