:root {
  --font: 'Inter', sans-serif;

  --background: #FAFAFA;
  --background_second: #FAFAFB;

  --primary: #333333;
  --secondary: #A3ACB3;

  --error: #e44b4b;
  --red: #EC5B75;
  --red_light: #FDEFF1;
  --green: #D0F4B4;
  --warning: #ffae00;
  --info: #3796f6;
  --success: #2cc197;
  --light: #ebf3fa;
  --dark: #212529;

  --white: #ffffff;
  --black: #000000;
  --gray: #ababab;

  --container: 1390px;
  --small-container: 890px;
}
